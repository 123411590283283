import React, { useEffect, useState } from 'react'
import axios from 'axios'
import DropboxChooser from 'react-dropbox-chooser'
import { SalesLink } from '../Components/AllContexts'

function PortalManage() {

    const [ link, setLink ] = useState({
        name: '',
        price: '',
        discountedprice: '',
        percent: '',
        description: '',
        url1: '',
        url2: ''
    })
    const API_Key = process.env.REACT_APP_API_Key
    const [ letsEdit, setLetsEdit] = useState(false)

    const handleChange =(e)=>{
        const name = e.target.name
        const value = e.target.value

        setLink((prevState)=>({
            ...prevState,
            [name]: value,
        }))
            if(link.price && link.percent ){
                const discountedprice = (link.percent / 100) * link.price
                console.log(discountedprice)
                const finalprice = Math.round(link.price - discountedprice)
                setLink((prevState)=>({
                    ...prevState,
                    ['discountedprice']: finalprice
                }))
            }
        
    }

    const handleSubmit = async(e)=>{
        e.preventDefault()
        if(link.url1 === '' && link.url2 === '') return
            const url = SalesLink
            try{              
                const response = await axios.post(url, JSON.stringify(link), {
                    headers: {
                      'Content-Type': 'application/x-www-form-urlencoded'
                    }
                  });
                window.location.reload()
                
            } catch(e) {
                console.log(e)
        }
    }

    const saveImage = async (files) => {
        const link = files[0].link
        const modifiedUrl = link.replace("dropbox.com", "dl.dropboxusercontent.com");
        if(link.url1 === '' || link.url2 !==''){
            setLink((prevState)=>({
                ...prevState,
                ['url1']: modifiedUrl
            }))
        }   
      }

      const saveSecondImage = (files)=>{
        const link = files[0].link
        const modifiedUrl = link.replace("dropbox.com", "dl.dropboxusercontent.com");
        setLink((prevState)=>({
            ...prevState,
            ['url2']: modifiedUrl
        }))
      }
  return (
        
    <form className='PortalCatalogue_container Manage' onSubmit={handleSubmit}>
        <h2>Set up your Combo Sales</h2>
        {!letsEdit && <span onClick={()=>setLetsEdit(!letsEdit)}>Enable Edit</span>}
        {letsEdit && <span className='disable' onClick={()=>setLetsEdit(!letsEdit)}>Disable Edit</span>}
        <div className="input_card">
            <label htmlFor="name">Name your sale offer:</label>
            <input type="text" id='name' name='name' placeholder='Sales Name' value={link.name} onChange={handleChange} disabled={!letsEdit} required />
        </div>
        <div className="input_card">
            <label htmlFor="price">What is the actual price:</label>
            <input type="number" id='price' name='price' placeholder='Price before discount' value={link.price} onChange={handleChange} disabled={!letsEdit} required />
        </div>
        <div className="input_card">
            <label htmlFor="percent">Discount % on actual price</label>
            <input type="text" id='percent' name='percent'  placeholder='Data here will be used to calculate discounted price' value={link.percent} onChange={handleChange} disabled={!letsEdit} required/>
        </div>
        <div className="input_card">
            <label htmlFor="discountedprice">Discounted Price:</label>
            <input type="text" id='discountedprice' name='discountedprice' placeholder='This will be calculated automatically' value={link.discountedprice} onChange={handleChange} disabled={true} required />
        </div>
        <DropboxChooser 
                appKey={API_Key}
                success={saveImage}
                cancel={() => console.log('closed')}
                multiselect={true}
                extensions={['.jpg','.jpeg','.png', '.webp']} >
                <div className={`${link.url1 ? 'dropboxWithFile' : 'dropbox-button '}`}>Upload First Product</div>          
        </DropboxChooser>
        <DropboxChooser 
                appKey={API_Key}
                success={saveSecondImage}
                cancel={() => console.log('closed')}
                multiselect={true}
                extensions={['.jpg','.jpeg','.png', '.webp']} >
                <div style={{ marginTop: '10px' }} className={`${link.url2 ? 'dropboxWithFile' : 'dropbox-button '}`}>Upload Second Product</div>          
        </DropboxChooser>
        <div className="input_card">
            <label htmlFor="description">Description: </label>
            <textarea style={{ borderRadius: 10 }} name="description" id="description" value={link.description} cols="20" rows="5" required onChange={handleChange} ></textarea>
        </div>
        <button>Submit</button>
    </form>
  )
}

export default PortalManage