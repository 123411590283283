import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useNavigate } from 'react-router-dom'

function Login() {

    const [ account, setAccount ] = useState({
        username: '',
        password: '',
    })
    const [cookies, setCookie, removeCookie] = useCookies(['user'])
    const navigate = useNavigate()

    const handleSubmit =(e)=>{
        e.preventDefault()
        try {
            if((account.username).toLowerCase() ==='nyi intan' && account.password === 'nyiintanspecial123'){
                setCookie('account', 'access granted')
                return window.location.reload()
            }
            return alert('Incorrect username or password. Please try again!!')
        } catch (error) {
            console.log(error)
            alert('Something went wrong. Please try again later!!')
        }
    }

    const handleChange =(e)=>{

        const name = e.target.name
        const value = e.target.value

        setAccount((prevState)=>({
            ...prevState,
            [name]: value,
        }))
    }

    useEffect(()=>{
        const portal = cookies.account
        if(portal) return navigate('/admin/portal')
        return
    }, [])

  return (
    <>
    <div className='login_container'>
        <h2>NYI INTAN <br /> ADMIN PORTAL</h2>
        <form onSubmit={handleSubmit}>
            <div className="input_card">
                <label htmlFor="username">Username: </label>
                <input type="text" placeholder='Username' name='username' id='username' onChange={handleChange} value={account.username}  />
            </div>
            <div className="input_card">
                <label htmlFor="password">Password: </label>
                <input type="password" value={account.password} name='password' placeholder='Password' id='password' onChange={handleChange} />
            </div>
            <button>Login</button>
        </form>
    </div>
    </>
  )
}

export default Login