import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { CataloguesLink } from '../Components/AllContexts'

function PortalCatalogue() {

    const [ link, setLink ] = useState({
        halal: '',
        skincare: '',
        restaurant: ''
    })
    const [ letsEdit, setLetsEdit] = useState(false)

    const handleChange =(e)=>{

        const name = e.target.name
        const value = e.target.value

        setLink((prevState)=>({
            ...prevState,
            [name]: value,
        }))
    }

    const handleSubmit = async()=>{
        if(link.halal || link.skincare || link.restaurant){
            const url = CataloguesLink
            try{              
                const response = await axios.post(url, JSON.stringify(link), {
                    headers: {
                      'Content-Type': 'application/x-www-form-urlencoded'
                    }
                  });
                window.location.reload()
                
            } catch(e) {
                console.log(e)
            }
        }
    }

    const fetchLink = async()=>{
        const url = CataloguesLink
        try {
            const response = await axios.get(url)
            setLink(response.data.data[0])            
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(()=>{
        fetchLink()
    }, [])
  return (
    <div className='PortalCatalogue_container'>
        <h2>Add Canva Link of your Catalogues</h2>
        {!letsEdit && <span onClick={()=>setLetsEdit(!letsEdit)}>Enable Edit</span>}
        {letsEdit && <span className='disable' onClick={()=>setLetsEdit(!letsEdit)}>Disable Edit</span>}
        <div className="input_card">
            <label htmlFor="halal">Sembako Catalogue</label>
            <input type="text" id='halal' name='halal' placeholder='e.g: https://www.example.com/sembako' value={link.halal} onChange={handleChange} disabled={!letsEdit} />
        </div>
        <div className="input_card">
            <label htmlFor="skincare">Skincare Catalogue</label>
            <input type="text" id='skincare' name='skincare' placeholder='https://www.example.com/skincare' value={link.skincare} onChange={handleChange} disabled={!letsEdit} />
        </div>
        <div className="input_card">
            <label htmlFor="restaurant">Restaurant Catalogue</label>
            <input type="text" id='restaurant' name='restaurant'  placeholder='https://www.example.com/restaurant' value={link.restaurant} onChange={handleChange} disabled={!letsEdit}/>
        </div>
        <button onClick={handleSubmit}>Submit</button>
    </div>
  )
}

export default PortalCatalogue