import React, { useState, useEffect, useContext } from 'react'
import product from '../img/product.webp'
import axios from 'axios'
import PortalProductInputComponenet from './PortalProductInputComponenet'
import { ProductListContext } from '../Components/AllContexts'


function ProductDetails ({product, onRowClick}) {

  const handleRowClick = () => {
    onRowClick(product);
  };
  return (
    <tr onClick={handleRowClick}>
      <td><img src={product.image} alt="product" /></td>
      <td>{product.name}</td>
      <td>{product.category}</td>
      <td>¥ {product.price}</td>
      <td>{product.description}</td>
    </tr>
  )
}

function PortalHome() {

  const { allProductList, setAllProductList } = useContext(ProductListContext)
  const [ selectedProduct, setSelectedProduct ] = useState(null)

  // Function to handle row click and set the selected product
  const handleRowClick = (product) => {
    setSelectedProduct(product);
  };  

  return (
    <div className='homeComponent_container'>
      <div className="summary_info__container">
        <h2>All Products<span>Halal Foods & Skin Care</span> </h2>
        <div className="customer_list_container">
          <table>
            <thead>
            <tr>
              <th>Image</th>
              <th>Products</th>
              <th>Category</th>
              <th>Price</th>
              <th>Description</th>
            </tr>
            </thead>
            <tbody>
              { allProductList && allProductList.map((product)=> <ProductDetails key={product.no} product={product} onRowClick={handleRowClick} /> ) }
            </tbody>
          </table>
        </div>
      </div>
      <PortalProductInputComponenet selectedProduct={selectedProduct} setSelectedProduct={setSelectedProduct} />
    </div>
  )
}

export default PortalHome