import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie'

function DashBoardVNav({ toSelect }) {

  const [selectedItems, setSelectedItems] = useState([true, false, false, false]);
  const navigate = useNavigate()
  const [cookies, setCookie, removeCookie] = useCookies(['user'])

  const handleClick = (toNavigate) => {
    return navigate(`/admin/portal/${toNavigate}`)
  };

  const handleLogout = () =>{
    removeCookie('account')
    return navigate('/admin/login')
  }

  return (
    <div className='dashboardVNav_container'>
      <div className="brandName_card">
        <h2>NYI INTAN</h2>
        <p>Dashboard</p>
      </div>  
      <div className="vNav_menu">
        <ul>
        <li className={toSelect === 'home' ? 'selected' : ''} onClick={() => handleClick('')}>
          Home
        </li>
        <li className={toSelect === 'campaign' ? 'selected' : ''} onClick={() => handleClick('campaign')}>
          Campaign
        </li>
        <li className={toSelect === 'manage' ? 'selected' : ''} onClick={() => handleClick('manage')}>
          Manage Sales
        </li>
        <li className={toSelect === 'shipping' ? 'selected' : ''} onClick={() => handleClick('shipping')}>
          Shipping
        </li>
        <li onClick={handleLogout}>
          Logout
        </li>
        </ul>
      </div>
    </div>
  )
}

export default DashBoardVNav