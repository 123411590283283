import React, { useState, useContext, useEffect } from 'react'
import axios from 'axios';
// import { google } from 'googleapis';
import DropboxChooser from 'react-dropbox-chooser'
import { ProductListContext, ProductsLink, EditProductLink, DeleteProductLink } from '../Components/AllContexts';


function PortalProductInputComponenet({ selectedProduct, setSelectedProduct }) {

    const { allProductList } = useContext(ProductListContext)
    const [ allCategories, setAllCategories] = useState()
    const [newCategory, setNewCategory ] = useState(false)
    const[productData, setProductData] = useState({
        name: '',
        price: '',
        description: '',
        categories: '',
        url: '',
        no: ''
    });

    useEffect(()=>{
        if(selectedProduct){
            setProductData({
                name: selectedProduct.name,
                price: selectedProduct.price,
                description: selectedProduct.description,
                url: selectedProduct.image,
                categories: selectedProduct.category,
                no: selectedProduct.no
            })
        }
    }, [selectedProduct])

    const handleChange =(e)=>{
        const name = e.target.name
        const value = e.target.value

        setProductData((prevState)=>({
            ...prevState,
            [name]: value,
        }))
    }

    useEffect(() => {
        if(allProductList){
            const uniqueCategories = [...new Set(allProductList.map(product => product.category))];
            setAllCategories(uniqueCategories);
        }
    }, [allProductList]);


    const API_Key = process.env.REACT_APP_API_Key
        

    const saveImage = async (files) => {
        const link = files[0].link
        const modifiedUrl = link.replace("dropbox.com", "dl.dropboxusercontent.com");
        setProductData((prevState)=>({
            ...prevState,
            ['url']: modifiedUrl
        }))
        // console.log(productData)

      }

      const EditItem = async(e, todo)=>{
        e.preventDefault() 
        let url = EditProductLink
        if(todo === 'del'){
            url = DeleteProductLink
        } 
        try{
            const response = await axios.post(url, JSON.stringify(productData), {
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded'
                }
              });
            window.location.reload()
            
        } catch(e) {
            console.log(e)
        }
    }

    const publishItems = async(e)=>{
        e.preventDefault() 
        const url = ProductsLink
        try{
            const response = await axios.post(url, JSON.stringify(productData), {
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded'
                }
              });
            window.location.reload()
            
        } catch(e) {
            console.log(e)
        }
    }

    const revertBack = () =>{
        setSelectedProduct(null)
        return setProductData({
            name: '',
            price: '',
            description: '',
            url: '',
            categories: '',
            no: ''
        })
    }
  return (
    <div className='portalProductInputComponenet_container'>
        <h2>{!selectedProduct ? 'Add More': 'Edit'} Products</h2>
        <form>
        {/* <button className='back-btn' onClick={revertBack}>&#x1F519;</button>  */}
            <div className="input_card">
                <label htmlFor="productName">Name: </label>
                <input type="text" placeholder='Name' id='productName' name='name' value={productData.name} required onChange={handleChange}/>
            </div>
            <div className="input_card">
                <label htmlFor="productPrice">Price: </label>
                <input type="number" placeholder='price' id='productPrice' name='price' value={productData.price} required onChange={handleChange} />
            </div>
            <DropboxChooser 
                appKey={API_Key}
                success={saveImage}
                cancel={() => console.log('closed')}
                multiselect={true}
                extensions={['.jpg','.jpeg','.png', '.webp']} >
                <div className={`${productData.url ? 'dropboxWithFile' : 'dropbox-button '}`}>Upload Image</div>          
            </DropboxChooser>
            
            <div className="input_cards">
                <input type="checkbox" placeholder='Add New Category' id='add' onChange={()=>setNewCategory(!newCategory)} />
                <label htmlFor="add">Add Category</label>
            </div>
            <div className="input_card"> 
                {newCategory && <label htmlFor="productCategory">Category: </label>}
                {newCategory && <input type="text" placeholder='price' id='productCategory' name='categories' value={productData.categories} onChange={handleChange} />}
                {!newCategory && <select name="categories" id="categories" defaultValue={'SelectOne'} onChange={handleChange}>
                    <option value="SelectOne" disabled>Select One</option>
                    <option value="Skincare">Skincare</option>
                    { allCategories && allCategories.map((product)=><option key={product} value={product}>{product}</option>) }
                </select>}
            </div>
            <div className="input_card">
                <label htmlFor="description">Description: </label>
                <textarea name="description" id="description" value={productData.description} cols="20" rows="5" required onChange={handleChange}></textarea>
            </div>
            <div className="input_card">
                {!selectedProduct && <button onClick={publishItems}>Add</button>}
                {selectedProduct &&<button style={{ marginTop: 10, backgroundColor: 'red' }} onClick={EditItem}>Edit item</button>}   
                {selectedProduct && <div className='btns'>
                    <button className='back-btn' onClick={revertBack}>&#x1F519;</button> 
                    <button className='back-btn' onClick={(e)=>EditItem(e, 'del')}>&#x1f5d1;</button> 
                </div>}
            </div>
            
        </form>
    </div>
  )
}

export default PortalProductInputComponenet