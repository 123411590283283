import React, { useEffect } from 'react'
import HomePageSlideShow from '../SubComponents/HomePageSlideShow'
import ProductOnSale from '../SubComponents/ProductOnSale'
import ProductListing from '../SubComponents/ProductListing'
import baso1 from '../img/baso1.jpg'
import catalogue from '../img/catalogue.jpeg'
import baso2 from '../img/baso2.jpg'
import OurServices from '../SubComponents/OurServices'
import { useLocation } from 'react-router-dom'
import CategoriesMenu from '../SubComponents/CategoriesMenu'
import RecommendedProducts from '../SubComponents/RecommendedProducts'
import sahuni from '../img/skincare/sahuni.JPG'
import halal from '../img/halal.avif'
import quality from '../img/quality.avif'
import { useNavigate } from 'react-router-dom'

function WebBodyComponent() {

    const slides = [
        { image: catalogue, title: 'catalogue'},
        { image: baso1, title: 'Tulang Rangu' },
        { image: baso2, title: 'Geprek' },
      ]

      const { pathname } = useLocation();
      const navigate = useNavigate()

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

  return (
    <>
      <div className="homePageFront_container">
        <div className="menu_card">
        <CategoriesMenu />
        <div className="poster_card" onClick={()=>navigate('/skincare')}>
            <img src={sahuni} alt="sahuni" />
            <p>Visit our Skin Care Store</p>
        </div>
        </div>
        <div className="display_container">
          <div className="upperDisplay_card">
            <HomePageSlideShow parentWidth={100} slides={slides} />
            <div className="shopSticker_container">
              <img src={halal} alt="halal certified" />
              <img src={quality} alt="quality certified" />
            </div>
          </div>
          <ProductOnSale />
        </div>
        
      </div>
        <RecommendedProducts title='Products You May Like:' />
        <OurServices />
        <ProductListing productTitle='our best products' noNavBar={false}/> 
    </>
  )
}

export default WebBodyComponent