import React, { useState, useEffect, useContext } from 'react'
import NavBar from '../Components/NavBar'
import { useNavigate, useParams } from 'react-router-dom'
import { WhatsAppChatContext, ProductListContext, ShoppingCartContext } from '../Components/AllContexts'
import WhatsappChatInterFace from './WhatsappChatInterFace'
import OceanDesign from './OceanDesign'
import { addToCart } from './functions'

function Products ({product, handleNavigation}){

    const { updateCart, setUpdateCart  } = useContext(ShoppingCartContext)

    return(
        <div className="products-card">
                    <img src={product.image} alt="product" onClick={()=> handleNavigation(product.name)}/>
                    <div className="productInfo" onClick={()=> handleNavigation(product.name)}>
                        <p>{ product.name}</p>
                        <p className='price'>¥<span> {product.price}</span></p>
                    </div>
                    <button onClick={()=>addToCart(product, updateCart, setUpdateCart)}>Add to Cart</button>
                </div>
    )
}

function ProductListing({ productTitle, margin, noNavBar, targetRef }) {

    // const [ productList, setProductList ] = useState(Pics)
    const { allProductList, setAllProductList } = useContext(ProductListContext)
    let navigate = useNavigate('')
    const { displayChatInterface, setDisplayChatInterface } = useContext(WhatsAppChatContext)
    const { id } = useParams()
    const [ filteredCategoryList, setFilteredCategoryList ] = useState()

    const handleNavigation = (product) => {
        return navigate(`/products/one/${product}`)
    }

    useEffect(() => {
        // Scroll to the top when the component is mounted
        window.scrollTo(0, 0);
    }, []);

    useEffect(()=>{
        if(!productTitle && id && allProductList){
            const filteredCategoryArray = allProductList.filter(item => item.category === id);
            const filterProductArray = allProductList.filter(items => items.name.toLowerCase().includes(id.toLowerCase()))
            const combinedArray = [...filteredCategoryArray, ...filterProductArray];

            const uniqueArray = combinedArray.filter((item, index, self) =>
                index === self.findIndex(obj =>
                    obj.name === item.name && obj.no === item.no && obj.price === item.price
                )
            );
            setFilteredCategoryList(uniqueArray)
        } else if (productTitle !== 'Products for Your Skin' && allProductList){
            setFilteredCategoryList(allProductList)
        } else if (productTitle === 'Products for Your Skin' && allProductList){
            const filterSkinCareProducts = allProductList.filter(SkincareItem=>SkincareItem.category === 'Skincare')
            setFilteredCategoryList(filterSkinCareProducts)
        } 
    }, [productTitle, id, allProductList])
  return (
   <>
    {!displayChatInterface && !noNavBar && <NavBar />}
    {displayChatInterface && <WhatsappChatInterFace />}
    {!displayChatInterface && <div className='productlisting-container' ref={targetRef} style={{ marginTop: margin }}>
        <h3><span>Search for Products <br /></span>{productTitle ? productTitle: id}</h3>
        <div className="productslist">
            { productTitle ==='our best products' && filteredCategoryList && filteredCategoryList.map((product)=><Products product={product} key={product.no} handleNavigation={handleNavigation}/>).slice(0,12) }
            {productTitle !=='our best products' && filteredCategoryList && filteredCategoryList.map((product)=><Products product={product} key={product.no} handleNavigation={handleNavigation}/>) }
        </div>
        {productTitle === 'our best products' && <div className="btns">
            <button className='more-btn' onClick={()=> navigate('/products')}>View More Products &#8594;</button>
        </div>}
    </div>}
    {!displayChatInterface && <OceanDesign />}
   </>
  )
}

export default ProductListing