import React, { useContext } from 'react'
import logo from '../img/logo.jpg'
import facebook from '../img/facebook.png'
import insta from '../img/insta.png'
import tiktok from '../img/tiktok.png'
import whatsapp from '../img/whatsapp.png'
import Maps from './Maps.js'
import { DrawerMenuContext, ShoppingCartContext } from '../Components/AllContexts'
import { useNavigate } from 'react-router-dom'

function Footer() {

    const { displayMobileMenu, setDisplayMobileMenu } = useContext(DrawerMenuContext)
    const { showShoppingCarts, setShowShoppingCarts } = useContext(ShoppingCartContext)

    const navigate = useNavigate()

    const goToSocialMedia = (link)=>{
        return window.open(link, '_blank');
    }
  return (
    <div className='footer-container'>
        <div className="footer-brandInfo">
            <img src={logo} alt="NYI INTAN" />
            <p>&copy; INDONESIAN HALAL FOODS COMPANY IN JAPAN </p>
            <div className="brandmedia-card">
                <img src={insta} onClick={()=>goToSocialMedia('https://instagram.com/nyiintanofficial?igshid=NzZlODBkYWE4Ng==')} alt="insta" />
                <img src={tiktok} onClick={()=>goToSocialMedia('https://www.tiktok.com/@nyiintanyokohamaofficial?_t=8gCVNdPTGiI&_r=1')} alt="tiktok" />
                <img src={facebook} onClick={()=>goToSocialMedia('https://www.facebook.com/nyiintanofficial')} alt="facebook" />
                <img src={whatsapp} onClick={()=>goToSocialMedia('https://api.whatsapp.com/send?phone=+818014108889&text=Hello')} alt="whatsapp" />
            </div>
        </div>
        <div className="footer_details">
            <div className="footer-brandServices">
                <h3>Products & Services</h3>
                <ul>
                    <li onClick={()=>navigate('/')}>Halal Foods Groceries</li>
                    <li onClick={()=>navigate('/skincare')}>Skin Care Products</li>
                    <li>Indonesian Restaurant</li>
                </ul>
            </div>
            <div className="footer-brandServices">
                <h3 className='shipping'>Shipping Methods</h3>
                <small>Check for shipping charges </small>
                <ul>    
                    <li onClick={()=>navigate('/shipping/sagawa')}>Sagawa</li>
                    <li onClick={()=> navigate('/shipping/yamato')}>Yamato</li>
                </ul>
            </div>
            <div className="contactInfo">
                <h3>Contact Us</h3>
                <ul>
                    <li>Phone: 08014108889</li>
                    <li>WhatsApp: 08014108889</li>
                    <li>Email: nyiintan.official@gmail.com</li>
                </ul>
                </div>
        </div>
        <div className="brand-location">
            <h3>Maps</h3>
            {!displayMobileMenu && !showShoppingCarts && <Maps />}
        </div>
        <p style={{ zIndex: 1 }}>&copy; CopyRights reserved with NYI INTAN, 2023</p>
    </div>
  )
}

export default Footer