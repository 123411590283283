import React from 'react'
import poster from '../img/skincare/poster.JPG'

function ReusableComponent({content}) {

    const handleSendMessage = ()=>{
        const message = 'Hello, I would like to discuss with you on skincare.'
        return window.location.href=`https://api.whatsapp.com/send?phone=+818014108889&text=${message}`  
      }

  return (
    <div style={{ zIndex: 1 }} className={`resuableComponent_container ${content.className}`}>
       <div className="container">
            {content.picture && <div className="imageCard">
                <img src={content.picture.source} alt="poster" />
            </div>}
                
            <div className="card">
                <div className="textContent_card">
                    <h2>
                    NYI Intan - Your Skin Care Solution
                    </h2>
                    <p>
                        {content.description.src}
                    </p>
                </div>

                {content.buttonContent && <button onClick={handleSendMessage}>{content.buttonContent.text}</button>}
            </div>
       </div>
    </div>
  )
}

export default ReusableComponent