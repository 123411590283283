import React, { useContext, useState, useEffect } from 'react'
import search from '../img/search.png'
import { DrawerMenuContext, ProductListContext } from '../Components/AllContexts'
import { useNavigate } from 'react-router-dom'


function MobileDrawerMenu() {

    const { allProductList  } = useContext(ProductListContext)
    const { displayMobileMenu, setDisplayMobileMenu  } = useContext(DrawerMenuContext)
    const [ searchProductName, setSearchProductName ] = useState('')
    const [ allCategories, setAllCategories ] = useState()
    let navigate = useNavigate('')


    const handleCloseDrawerMenu = () => {
        setDisplayMobileMenu(false)
    }

    const handleCategoryNavigation = (category) =>{
        setDisplayMobileMenu(false)
        return navigate(`/products/${category}`)
    }

    const handleWebsiteNavigation = (toGo)=>{
        setDisplayMobileMenu(false)
        return navigate(`${toGo}`)
    }

    useEffect(() => {
        if(allProductList){
            // const uniqueCategories = [...new Set(allProductList.map(product => product.category))];
            const uniqueCategories = [...new Set(allProductList
                .filter(product => product.category !== null && product.category !== undefined && product.category !== "")
                .map(product => product.category)
              )];
            setAllCategories(uniqueCategories);
        }
      }, [allProductList]);

  return (
    <>
    <div className={`MobileDrawerMenu-container ${displayMobileMenu ? 'open': ''}`}>
        <div className="drawer_heading">
            <p>Product Category</p>
            <button className='close' onClick={handleCloseDrawerMenu}>X</button>
        </div>
        <ul>
            {allCategories && allCategories.map((product)=><li key={product} onClick={()=>handleCategoryNavigation(product)}>{product}</li>)}
        </ul>
        
        <p>Our shops</p>
        <ul>
            <li onClick={()=>handleWebsiteNavigation('/')}>Halal Foods</li>
            <li onClick={()=>handleWebsiteNavigation('/skincare')}>Skin Care</li>
        </ul>
        <p>About Shiping</p>
        <ul>
            <li onClick={()=>handleWebsiteNavigation('/shipping/Yamato')}>Yamato</li>
            <li onClick={()=>handleWebsiteNavigation('/shipping/Sagawa')}>Sagawa</li>
        </ul>
    </div>
    </>
  )
}

export default MobileDrawerMenu