import React from 'react'
import sahuni from '../img/skincare/sahuni.JPG'
import restaurant from '../img/restaurant.webp'
import catalogue from '../img/catalogue.jpeg'
import { useNavigate } from 'react-router-dom'

function OurServices() {

    const navigate = useNavigate()
  return (
    <div className='ourservices_container'>
        <p>Our Services</p>
        <div className="list_of_services">
            <div className="services-card">
                <img src={catalogue} alt="" />
                <small>Halal Shop</small>
            </div>
            <div className="services-card" onClick={()=> window.open('https://nyiintancafe.com/', '_blank')}>
                <img src={restaurant} alt="" />
                <small>Restaurants</small>
            </div>
            <div className="services-card" onClick={()=>navigate('/skincare')}>
                <img src={sahuni} alt="" />
                <small>Skin Care</small>
            </div>
        </div>  
    </div>
  )
}

export default OurServices