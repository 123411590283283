import React from 'react'
import Footer from './Footer'

function OceanDesign() {
  return (
    <div className='oceanDesign_container'>
      <div id="ocean_card">
        <Footer />
        <div className="wave-01"></div>
        <div className="wave-02"></div>
        <div className="wave-03"></div>
      </div>
    </div>
  )
}

export default OceanDesign