import { createContext } from 'react'

export const DrawerMenuContext = createContext();
export const WhatsAppChatContext = createContext();
export const ShoppingCartContext = createContext()
export const ProductListContext = createContext()
export const PortalContext = createContext()

// App Script Link
export const ProductsLink = 'https://script.google.com/macros/s/AKfycbyyq1pcMha4yKcCrf7UiTj4gQgaouwL9st-ARdcMymx7U5bpHQwbUtW-6osuElf_Vju/exec'
export const CataloguesLink = 'https://script.google.com/macros/s/AKfycbyyq1pcMha4yKcCrf7UiTj4gQgaouwL9st-ARdcMymx7U5bpHQwbUtW-6osuElf_Vju/exec?key1=catalogue'
export const EditProductLink = 'https://script.google.com/macros/s/AKfycbyyq1pcMha4yKcCrf7UiTj4gQgaouwL9st-ARdcMymx7U5bpHQwbUtW-6osuElf_Vju/exec?key1=editproduct'
export const DeleteProductLink = 'https://script.google.com/macros/s/AKfycbyyq1pcMha4yKcCrf7UiTj4gQgaouwL9st-ARdcMymx7U5bpHQwbUtW-6osuElf_Vju/exec?key1=delproduct'


export const ShippingLink = 'https://script.google.com/macros/s/AKfycbwAQBtmbjtQ81EufTctJBkUGi9P5eoTxLnLP-Izb6qEAdo6_Jbqkrx4Q04ff1VyRUpm/exec?key1=shipping'
export const SalesLink = 'https://script.google.com/macros/s/AKfycbwAQBtmbjtQ81EufTctJBkUGi9P5eoTxLnLP-Izb6qEAdo6_Jbqkrx4Q04ff1VyRUpm/exec'
