import React from 'react'
import picture from '../img/skincare/check.png'
import poster from '../img/skincare/poster.JPG'
import productone from '../img/skincare/productone.jpeg'
import producthold from '../img/skincare/holdproduct.jpeg'
import lip from '../img/skincare/lip.jpeg'

function Testimonial() {
  return (
    <div className="testominalComponent_container">
        <div className="cardInfo">
            <h1>Reviews</h1>
            <p>We are always delighted to serve our customers. Here are some reviews from our customer: </p>
        </div>
        <div className="testominal_cards">
            <div className="single_card">
                <img src={poster} alt="" />
                <p>このショップのスキンケア商品を使い始めてから数ヶ月、驚くほどの結果に感動しています。肌がずっと滑らかで健康的になりました。提案していただいた商品は、私の肌質にぴったりでした。間違いなくリピーターになります！</p>
            </div>
            <div className="single_card">
                <img src={lip} alt="" />
                <p>As someone who's always struggled with finding the right skincare routine, I was pleasantly surprised by the personalized recommendations I received from this store owner. The products she suggested have truly transformed my skin. I can confidently say that this is the best my skin has ever looked and felt.</p>
                
            </div>
            <div className="single_card">
                <img src={productone} alt="" />
                <p>I've been using skincare products from this store for a few months now, and I'm amazed by the results. My skin feels so much smoother and healthier. The recommendations they provided were perfect for my skin type. I'm definitely wanna buy more from them.</p>
                
            </div>
            <div className="single_card">
                <img src={producthold} alt="" />
                <p>Saya telah menggunakan produk perawatan kulit dari toko ini selama beberapa bulan, dan saya kagum dengan hasilnya. Kulit saya terasa lebih halus dan sehat. Rekomendasi yang diberikan sangat sesuai dengan jenis kulit saya. Saya pasti akan menjadi pelanggan setia sekarang! </p>
            </div>
        </div>
    </div>
  )
}

export default Testimonial