import { useCallback, useEffect, useRef, useState, useContext } from "react";
import { DrawerMenuContext, ShoppingCartContext } from "../Components/AllContexts";

const HomePageSlideShow = ({ slides, parentWidth, divWidth, divHeight, noMargin }) => {
  const timerRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const { displayMobileMenu, setDisplayMobileMenu  } = useContext(DrawerMenuContext)
  const { showShoppingCarts } = useContext(ShoppingCartContext)


  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = useCallback(() => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  }, [currentIndex, slides]);

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  const getSlideStylesWithBackground = (slideIndex) => ({
    backgroundImage: `url(${slides[slideIndex].image})`,
    width: `${parentWidth}%`,
    height: '100%',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  });

  const getSlidesContainerStylesWithWidth = () => ({
    width: `${parentWidth * slides.length}%`,
    transform: `translateX(${-(currentIndex * parentWidth/3)}%)`,
  });

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      goToNext();
    }, 4000);

    return () => clearTimeout(timerRef.current);
  }, [goToNext]);

  return (
    <div className="slideshow-container" style={{ pointerEvents: displayMobileMenu ? 'none' : 'auto', opacity: displayMobileMenu ? 0.5 : 1, width: divWidth, height: divHeight, marginTop: noMargin }} >
      <div style={{ display: showShoppingCarts ? 'none': 'block' }}>
        <div onClick={goToPrevious} className="leftArrowStyles">
          ❰
        </div>
        <div onClick={goToNext} className="rightArrowStyles">
          ❱
        </div>
      </div>

      <div className="slidesContainerOverflowStyles">
        <div style={getSlidesContainerStylesWithWidth()} className="slidesContainerStyles">
          {slides.map((_, slideIndex) => (
            <div
              key={slideIndex}
              style={getSlideStylesWithBackground(slideIndex)}
            ></div>
          ))}
        </div>
      </div>

      <div className="navigationdots-container">
        {slides.map((slide, slideIndex) => (
            <div
              className="dotStyle"
              key={slideIndex}
              onClick={() => goToSlide(slideIndex)}
            >
              ●
            </div>
        ))}
      </div>
    </div>
  );
};

export default HomePageSlideShow;