import React, { useState, useEffect, useContext } from 'react'
import { WhatsAppChatContext, ProductListContext, ShoppingCartContext } from '../Components/AllContexts'
import { useNavigate } from 'react-router-dom'
import { addToCart } from './functions'

function Products ({n, handleNavigation, getContainerStyles}){

    const { updateCart, setUpdateCart  } = useContext(ShoppingCartContext)

    return(
    <div style={getContainerStyles()} className="products-card">
        <img src={n.image} alt="product" onClick={()=> handleNavigation(n.name)}/>
        <div className="productInfo" onClick={()=> handleNavigation(n.name)}>
            <p>{n.name}</p>
            <p>¥ <span>{n.price}</span></p>
        </div>
        <button onClick={()=>addToCart(n, updateCart, setUpdateCart)} >Add to Cart</button>
    </div>
    )
}

function RecommendedProducts({title, inCategory, displayedProduct}) {

    const { allProductList, setAllProductList } = useContext(ProductListContext)
    const [ filteredArray, setFilteredArray ] = useState()

    
    let navigate = useNavigate('')
    const { displayChatInterface, setDisplayChatInterface } = useContext(WhatsAppChatContext)
    const [ currentIndex, setCurrentIndex ] = useState(400)


    const handleNavigation = (product) => {
        return navigate(`/products/one/${product}`)
    }

    const changeIndex = (sign) =>{
        if(sign === '+'){
            if (currentIndex === 1200) return
            return setCurrentIndex(currentIndex + 400)
        }
        if(currentIndex === 400) return
        return setCurrentIndex(currentIndex - 400)
    }

    const getContainerStyles = ()=>({
        transform: `translateX(${-(currentIndex - 400)}%)`
    })

    useEffect(()=>{
        if(title === 'Best Selling Skin Care Products' && allProductList && !inCategory){
            const filterSkinCareProducts = allProductList.filter(SkincareItem=>SkincareItem.category === 'Skincare')
            return setFilteredArray(filterSkinCareProducts)
        } else if(inCategory && allProductList){
            const filterSkinCareProducts = allProductList.filter(SkincareItem=>SkincareItem.category === inCategory && SkincareItem.name !== displayedProduct )
            return setFilteredArray(filterSkinCareProducts)
        } else if(allProductList && title !== 'Best Selling Skin Care Products') {
            const filterSkinCareProducts = allProductList.filter(SkincareItem=>SkincareItem.category !== 'Skincare')
            setFilteredArray(filterSkinCareProducts)
        }
    }, [allProductList, title])

  return (
    <div className='recommended_products_container'>
        <div className="recommended_products_header">
            <p>{title}</p>
            <div className="btns">
                <button onClick={()=>changeIndex('-')}>&#60;</button>
                <button onClick={()=>changeIndex('+')}>&#62;</button>
            </div>
        </div>
        <div className="recommended_productslist_list">
            { allProductList && !filteredArray && allProductList.map((n)=>
               <Products n={n} handleNavigation={handleNavigation} key={n.no} getContainerStyles={getContainerStyles} />
            ) }
            { allProductList && filteredArray && filteredArray.map((n)=>
               <Products n={n} handleNavigation={handleNavigation} key={n.no} getContainerStyles={getContainerStyles} />
            ) }
            <div style={getContainerStyles()} className="products-card extra">
                <p>Click to view more products</p>
                <button onClick={()=> navigate('/products')}>View More</button>
            </div>
        </div>
    </div>
  )
}

export default RecommendedProducts