import React, { useContext, useState, useRef, useEffect } from 'react'
import HomePageSlideShow from '../SubComponents/HomePageSlideShow'
import catalogue from '../img/skincare/portfolio.jpeg'
import baso1 from '../img/skincare/shampoo.jpeg'
import baso2 from '../img/skincare/check.png'
import organic from '../img/skincare/organic.jpeg'
import RecommendedProducts from '../SubComponents/RecommendedProducts'
import ReusableComponent from '../SubComponents/ReusableComponent'
import ProductListing from '../SubComponents/ProductListing'
import Testimonial from '../SubComponents/Testimonial'
import SkinCareComponentNavBar from '../SubComponents/SkinCareComponentNavBar'
import { DrawerMenuContext, ShoppingCartContext } from '../Components/AllContexts'
import { useParams } from 'react-router-dom'
import SkinCareDrawerMenu from '../SubComponents/SkinCareDrawerMenu'
import ShoppingCart from '../SubComponents/ShoppingCart'

function SkinCareStoreComponent() {

    const {  openDrawerMenu, setOpenDrawerMenu } = useContext(DrawerMenuContext)
    const { showShoppingCart, setShowShoppingCart } = useContext(ShoppingCartContext)


    const productListingRef = useRef(null);

    const { id } = useParams()
    const slides = [
        { image: catalogue, title: 'catalogue'},
        { image: baso1, title: 'Tulang Rangu' },
        { image: baso2, title: 'Geprek' },
        // { image: organic, title: 'organic'}
      ]

    //   heading, picture, description, buttonContent, cssContent

    const brandDisplayCard = {
        posterDiv:{ 
            className: 'posterdiv_container',
            heading: {
                src: 'NYI Intan - Your Skin Care Solution',
                color: 'black',
                size: '2rem',
                weight: '600'
            },
            picture: {
                source: organic,
            },
            description: {
                src: "NYI Intan is your trusted destination for premium skincare products that cater to diverse skin needs. With a focus on quality and effectiveness, we bring you a curated selection of products designed to enhance your skin's natural beauty. Discover a wide range of skincare solutions meticulously crafted to address various skin concerns. Whether you're seeking hydration, rejuvenation, or a radiant complexion, NYI Intan has the perfect solution for you.",
                color: 'white',
                size: 'larger',
                weight: '600'
            },
            buttonContent: {
                bgColor: 'black',
                text: 'Join Our Skin Care Program',
                radius: '20px',
                textColor: 'white',
                width: 'fit-content',
            },
            cssContent: {
                divColor: 'goldenrod',
                divWidth: '100vw',
                divHeight: '100vh',
                display: 'flex',
                direction: 'row-reverse',
                horizontal: 'center',
                vertical: 'center'
            }
        },
        plainDiv:{
            className: 'plaindiv_container',
            heading: {
                src: 'Join Our Radiant Community',
                color: 'black',
                size: '1rem',
                weight: '600'
            },
            description: {
                src: "We're excited to offer you an exclusive opportunity to be a part of our community. Experience the transformative power of our products and join the ranks of confident and radiant individuals who trust us for their skincare needs.",
                color: 'black',
                size: 'large',
                weight: '400'
            },
            cssContent: {
                divColor: 'white',
                divWidth: '100vw',
                divHeight: '100vh',
                display: 'flex',
                direction: 'row-reverse',
                horizontal: 'center',
                vertical: 'center'
            }
        },
        introDiv: {
            className: 'introDiv_component',
            heading: {
                src: 'Hyde Beauty Merupakan Brand Perawatan Kecantikan yang di',
                color: 'black',
                size: '1rem',
                weight: '600'
            },
            picture: {
                source: baso1,
            },
            description: {
                src: 'Hyde Beauty Merupakan Brand Perawatan Kecantikan yang di Percaya Ribuan Wanita Indonesia, Hyde Beauty Sendiri Selalu Banjir Orderan Karena Banyak Peminatnya. Karena Itu, Kami Buka Kesempatan Ini Buat Kamu Berikutnya',
                color: 'black',
                size: 'large',
                weight: '400'
            },
            cssContent: {
                divColor: 'white',
                divWidth: '100vw',
                divHeight: '100vh',
                display: 'flex',
                direction: 'row-reverse',
                horizontal: 'center',
                vertical: 'center'
            }
        }
    }

    const scrollToProductListing = () => {
        if (productListingRef.current) {
          productListingRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      };

      useEffect(() => {
        const timeout = setTimeout(() => {
          if (id === 'products') {
            scrollToProductListing();
          }
        }, 1000); // Delay for 2 seconds
    
        return () => {
          // Clear the timeout when the component unmounts
          clearTimeout(timeout);
        };
      }, [id, openDrawerMenu]);
    
  return (
    <>
    <div>
        <div className="navbar-container">
            <ShoppingCart /> 
        </div>
        <SkinCareComponentNavBar scrollToProductListing={scrollToProductListing}/>
        <div className="containerBody">
            {!showShoppingCart && <div className="skinCareFront_card">
                {!openDrawerMenu && !showShoppingCart && <HomePageSlideShow parentWidth={100} slides={slides} divWidth='100vw' divHeight='80vh' noMargin='0' />}
                {!showShoppingCart && <SkinCareDrawerMenu scrollToProductListing={scrollToProductListing}/>}  
            </div>}
            {!openDrawerMenu && <ReusableComponent content={brandDisplayCard.posterDiv} />}
            {!openDrawerMenu && <ReusableComponent content={brandDisplayCard.plainDiv} />}
            {!openDrawerMenu && <RecommendedProducts title='Best Selling Skin Care Products' />}
            {!openDrawerMenu && <Testimonial />}
            
            {!openDrawerMenu &&  <div ref={productListingRef} >
                <ProductListing productTitle='Products for Your Skin' noNavBar={true} />
            </div>}
        </div>
    </div>
    </>
  )
}

export default SkinCareStoreComponent