// require('dotenv').config();
import './App.css';
import { useState, useEffect } from 'react';
import { DrawerMenuContext, ProductsLink, WhatsAppChatContext, ShoppingCartContext, ProductListContext } from './Components/AllContexts';
import { BrowserRouter, Routes, Route} from 'react-router-dom'
import MainPageComponent from './Pages/MainPageComponent';
import ProductListing from './SubComponents/ProductListing';
import SingleProductPage from './SubComponents/SingleProductPage';
import SkinCareStoreComponent from './Pages/SkinCareStoreComponent';
import Portal from './Pages/Portal'
import axios from 'axios';
import { checkStorage } from './SubComponents/functions';
import ShippingDetails from './SubComponents/ShippingDetails';
import Error from './SubComponents/Error';
import Login from './SubComponents/Login';
import { useCookies } from 'react-cookie'


function App() {

  const [ cookies ] = useCookies(['user'])
  const [ displayMobileMenu, setDisplayMobileMenu ] = useState(false)
  const [ displayChatInterface, setDisplayChatInterface ] = useState(false)
  const [ showShoppingCarts, setShowShoppingCarts ] = useState(false)
  const [ openDrawerMenu, setOpenDrawerMenu ] = useState(false)
  const [ allProductList, setAllProductList ] = useState()
  const [ productsOnCart, setProductsOnCart ] = useState()
  const [ updateCart, setUpdateCart ] = useState(false)
  const [ allCatalogues, setAllCatalogues ] = useState()

  const portalLogin = cookies.account

  const fetchProducts =  async()=>{
    const url = ProductsLink
    try {
      const response = await axios.get(url)
      const productLine = response.data.data.slice(1)
      setAllProductList(productLine)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(()=>{
    fetchProducts()
  }, [])

  useEffect(()=>{
    const check = checkStorage()
    if(!check) return setProductsOnCart([])
    if(check) return setProductsOnCart(check)
  }, [updateCart])


  useEffect(() => {
    // Prevent scrolling when displayMobileMenu is true
    if (displayMobileMenu) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [displayMobileMenu]);

  return (
    <BrowserRouter>
    <div className="App">
      <DrawerMenuContext.Provider value={{ displayMobileMenu, setDisplayMobileMenu, openDrawerMenu, setOpenDrawerMenu, allCatalogues, setAllCatalogues  }}>
        <WhatsAppChatContext.Provider value={{ displayChatInterface, setDisplayChatInterface }}>
          <ShoppingCartContext.Provider value={{ showShoppingCarts, setShowShoppingCarts, productsOnCart, setProductsOnCart, updateCart, setUpdateCart }}>
            <ProductListContext.Provider value={{ allProductList, setAllProductList }}>
              <Routes>
                <Route path='/' element={ <MainPageComponent /> } />
                <Route path='/products' element={<ProductListing productTitle='All Products' margin='20vh' />}/>
                <Route path='/products/:id' element={<ProductListing margin='20vh' />}/>
                <Route path='/products/one/:id' element={<SingleProductPage />}/>
                <Route path='/shipping/:id' element={<ShippingDetails />} />
                <Route path='/skincare' element={<SkinCareStoreComponent />}/>
                <Route path='/skincare/:id' element={<SkinCareStoreComponent />}/>
                {portalLogin && <Route path='/admin/portal' element={<Portal toShow='home' />} />}
                {portalLogin && <Route path='/admin/portal/campaign' element={<Portal toShow='campaign' />} />}
                {portalLogin && <Route path='/admin/portal/manage' element={<Portal toShow='manage' />} />}
                {portalLogin && <Route path='/admin/portal/shipping' element={<Portal toShow='shipping' />} />}
                <Route path='/admin/login' element={<Login />} />
                <Route path='*' element={<Error />} />
              </Routes>
          </ProductListContext.Provider>
          </ShoppingCartContext.Provider>
        </WhatsAppChatContext.Provider>
      </DrawerMenuContext.Provider>
    </div>
    </BrowserRouter>
  );
}

export default App;
