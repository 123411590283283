import React, { useState, useContext } from 'react'
import { DrawerMenuContext } from '../Components/AllContexts'
import { useNavigate } from 'react-router-dom'

function SkinCareDrawerMenu({ scrollToProductListing }) {

    const {openDrawerMenu, setOpenDrawerMenu } = useContext(DrawerMenuContext)
    const navigate = useNavigate()

    const handleCategoryNavigation = (toGo)=>{
        setOpenDrawerMenu(!openDrawerMenu)
        return navigate(`${toGo}`)
    }

    

    const handleCloseDrawerMenu = ()=>{
        return setOpenDrawerMenu(!openDrawerMenu)
    }

  return (
    <>
    <div className={`SkinCareDrawerMenu-container ${openDrawerMenu ? 'open': ''}`}>
    <div className="drawer_heading">
        <p>NYI INTAN BEAUTY</p>
        <button className='close' onClick={handleCloseDrawerMenu}>X</button>
    </div>
    <ul>
        <p onClick={()=>handleCategoryNavigation('/skincare')}><li>Home</li></p>
        <p onClick={()=>handleCategoryNavigation('/skincare/products')}><li>Our Products</li></p>
    </ul>
    
    <p>Our shops</p>
    <ul className='shops_card'>
        <li onClick={()=>handleCategoryNavigation('')}>Skin Care</li>
        <li onClick={()=>handleCategoryNavigation('/')}>Halal Foods</li>
    </ul>
</div>
</>
  )
}

export default SkinCareDrawerMenu