import React, { useContext } from 'react'
import { WhatsAppChatContext } from '../Components/AllContexts'
import WebBodyComponent from '../Components/WebBodyComponent'
import WhatsappChatInterFace from '../SubComponents/WhatsappChatInterFace'
import Footer from '../SubComponents/Footer'
import MapComponent from '../SubComponents/Maps'

function MainPageComponent() {

    const { displayChatInterface, setDisplayChatInterface } = useContext(WhatsAppChatContext)

  return (
    <>
        <WhatsappChatInterFace />
        {!displayChatInterface && <WebBodyComponent />}
    </>
  )
}

export default MainPageComponent