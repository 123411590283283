import React from 'react';
import { MapContainer, TileLayer, Marker, Popup, CircleMarker, Tooltip } from 'react-leaflet';
import 'leaflet/dist/leaflet.css'
import { Link } from 'react-router-dom';
import Leaflet from 'leaflet';


Leaflet.Icon.Default.imagePath = '//cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.4/images/'

const MapComponent = () => {
  const center = [35.45805150384191, 139.6003726602731]; // Latitude and Longitude for the initial position
  const markerPositionHalal = [35.45805150384191, 139.6003726602731]; // Latitude and Longitude for the marker position
  const markerPositionRestaurant = [35.45805150384281, 139.6010826602621];
  return (
    <>
    <div style={{ position: 'relative', width: '90vw', height: '250px', margin: 'auto' }}>
    <MapContainer center={center} zoom={30} style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, width: '100%', height: '100%'  }}>
      <TileLayer
        url="https://a.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <Marker position={markerPositionRestaurant} >
        <Tooltip permanent>
          NYI INTAN Restaurant <br />
          <Link to='https://www.google.com/maps/dir/35.4628463,139.5993176/nyi+intan' target='_blank' >View our restaurants <br />in Google Maps</Link>
        </Tooltip>
      </Marker> 
      <CircleMarker
      key={markerPositionHalal}
      pathOptions={{ color: 'green' }}
      radius={20}
      center={[markerPositionHalal[0], markerPositionHalal[1]]}>
        <Tooltip permanent >
          NYI INTAN Halal  <br />
          <Link to='https://www.google.com/maps/dir/35.4628463,139.5993176/nyi+intan' target='_blank' >View Halal Shop <br /> in Google Maps</Link>
        </Tooltip>
      </CircleMarker>

      {/* <CircleMarker 
        key={points} 
        pathOptions={{ color: 'royalblue' }}
        radius={8}
        center={[points[0], points[1]]}>
        <Tooltip className='custom-tooltip' permanent >
          {points[2]}
        </Tooltip>

        </CircleMarker> */}
    </MapContainer>
    </div>
    <div className="link">
      <Link to='https://www.google.com/maps/dir/35.4628463,139.5993176/nyi+intan' style={{ color: 'white' }} target='_blank' >View in Google Maps</Link>
    </div>
    </>
  );
};

export default MapComponent;
