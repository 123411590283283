import React, { useState, useEffect, useContext } from 'react'
import logo from '../img/logo.jpg'
import whatsapp from '../img/whatsapp.png'
import cart from '../img/cart.png'
import { WhatsAppChatContext, DrawerMenuContext, ShoppingCartContext, CataloguesLink } from '../Components/AllContexts'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

function NavBarBrandNameHeader() {

  const [ link, setLink ] = useState({
    halal: '',
    skincare: '',
    restaurant: ''
  })
  const [ displayMsg, setDisplayMsg ] = useState(false)
  const { displayChatInterface, setDisplayChatInterface } = useContext(WhatsAppChatContext)
  const { displayMobileMenu, setDisplayMobileMenu, allCatalogues, setAllCatalogues  } = useContext(DrawerMenuContext)
  const { showShoppingCarts, setShowShoppingCarts, productsOnCart, setProductsOnCart } = useContext(ShoppingCartContext)
  const navigate = useNavigate()

    const displayChat=()=>{
      setDisplayChatInterface(!displayChatInterface)
    }

    const displayCart = ()=>{
      setShowShoppingCarts(!showShoppingCarts)
    }

    const fetchLink = async()=>{
      const url = CataloguesLink
      try {
          const response = await axios.get(url)
          setLink(response.data.data[0]) 
          setAllCatalogues(response.data.data[0])           
      } catch (error) {
          console.log(error)
      }
  }

  useEffect(()=>{
      fetchLink()
  }, [])

  const handleNavigate = (e)=>{
    const toWhere = e.target.value
    if(toWhere === 'halal'){
      return window.open(link.halal, '_blank');
    } else if(toWhere === 'skincare'){
      return window.open(link.skincare, '_blank');
    } else if (toWhere === 'restaurant'){
      return window.open(link.restaurant, '_blank');
    }
  }

  return (
    <>
    {!displayMsg && <div className='navbarbrandnameheader-container' style={{ pointerEvents: displayMobileMenu || showShoppingCarts  ? 'none': 'auto', opacity: displayMobileMenu || showShoppingCarts ? 0.5: 1 }}>
        <div className="brand-logo-card" onClick={()=>navigate('/')}>
            <img src={logo} alt="logo" className='brand-logo' />
            <h4>NYI <br /> INTAN</h4>
        </div>
        <div className="other_details">
          <div className="catalogues">
              <small>Download Catalogue</small>
              <select name="catlogues" id="catalogues" onChange={handleNavigate}>
                <option value="select">Select One</option>
                {link.halal && <option value="halal" >Halal Foods</option>}
                {link.skincare && <option value="skincare" >Skin Care</option>}
                {link.restaurant && <option value="restaurant" >Restaurant Menu</option>}
              </select>
            </div>
          <div className="cart_container" onClick={displayCart}>
            <img src={cart} alt="phone" className='contacts-icon' />
            {productsOnCart && <p>{productsOnCart.length}</p>}
          </div>    
        </div>
    </div>}
    <div className="contactDetails-container" onClick={displayChat}>
            <img src={whatsapp} alt="phone" className='contacts-icon' />
            <div className="contacts-card">
                <small>Tap to Chat</small>
                <p>08014108889</p>
            </div>
        </div>
    </>
  )
  }

export default NavBarBrandNameHeader