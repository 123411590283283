import React, { useState } from 'react'
import NavBarBrandNameHeader from '../SubComponents/NavBarBrandNameHeader'
import NavBarMenu from '../SubComponents/NavBarMenu'
import { DrawerMenuContext } from './AllContexts'
import ShoppingCart from '../SubComponents/ShoppingCart'

function NavBar() {


  return (
    <>
      <div className="navbar-container" >
        <NavBarBrandNameHeader />
        <NavBarMenu />
        <ShoppingCart />
      </div>
    </>
  )
}

export default NavBar