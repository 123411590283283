import React, { useEffect, useState, useContext } from 'react'
import trust from '../img/trust.png'
import { ShoppingCartContext } from '../Components/AllContexts';
import { addToCart, removeFromCart } from './functions';

function Cart({n}) {

  const { updateCart, setUpdateCart  } = useContext(ShoppingCartContext)

  return(
    <>
    <div className="products">
      <div className="product-image">
          <img src={n.image}alt="product" />
      </div>
      <div className="product-info">
        <p>{n.name}</p>
        <h3>¥ {n.price}</h3>
      </div>
      <div className="counter">
        <button onClick={()=>removeFromCart(n, updateCart, setUpdateCart)} className='subtract'>-</button>
        <span>{n.qty}</span>
        <button onClick={()=>addToCart(n, updateCart, setUpdateCart)} className='add'>+</button>
      </div>
      </div>
    </>
  )
}

function ShoppingCart({ toDo }) {

  const { showShoppingCarts, setShowShoppingCarts, productsOnCart, setProductsOnCart  } = useContext(ShoppingCartContext)
  const [ messageToSeller, setMessageToSeller ] = useState('')

  const handleCloseCart = () => {
    setShowShoppingCarts(!showShoppingCarts)
  }

  const total = productsOnCart?.reduce((accumulator, item) => {
    if (typeof item.price === 'number' && typeof item.qty === 'number') {
      return accumulator + item.price * item.qty;
    }
    return accumulator;
  }, 0);

  const sendOrderList = () =>{
    let message = `Order Details:\n\n `;
    for (let product of productsOnCart) {
      message += `(${product.qty} x ${product.name} - ¥${product.price * product.qty}\n \n)  `;
    }
    message += ` ***\nMessage: ${messageToSeller}***`
    message += ` \nTotal: ¥${total}\n`;
  
    const encodedMessage = encodeURIComponent(message);
    const whatsappUrl = `https://api.whatsapp.com/send?phone=+818014108889&text=${encodedMessage}`;
  
    return window.open(whatsappUrl);
  }

  return (
    <>
     {productsOnCart && <div className={`shoppingcart-container ${showShoppingCarts? 'open': ''}`}>
        <div className="back_button">
          <button className='cancel' onClick={handleCloseCart}>Go Back</button>
        </div>
        <div className="cartDetails_card">
          <div className="cart_header">
            <button onClick={handleCloseCart}>X</button>
            <p>Checkout Items <span>{`(${productsOnCart.length})`}</span></p>
          </div>
          <div className="cart-msg">
            <img src={trust} alt="trusted" />
            <p>Trusted by our customers, preferred by our loyal grocery shoppers.</p>
          </div>
          <div className="cart">
            {productsOnCart && productsOnCart.map((n)=><Cart key={n.image} n={n} />) }
          </div>
        </div>
        
        <div className="carttotal-container">
          <div className="cartTotal_extra_card">
            <p>Write in below chat if you have any request:</p>
            <textarea name="request" id="request" cols="30" rows="10" placeholder='Any question related to your purchase' onChange={(e)=>setMessageToSeller(e.target.value)}></textarea>
          </div>
          <div className="price-details">
            <small>Total:</small>
            {productsOnCart && <h2>¥ {total}</h2>}
          </div>
          <button onClick={sendOrderList}>Place order</button>
        </div>
      </div>}
    </>
  )
}

export default ShoppingCart