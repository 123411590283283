export const checkStorage=()=>{
    const check = localStorage.getItem("OrderList")
    if(!check) return false
    return JSON.parse(check)
}

export const addToCart = (product, updateCart, setUpdateCart)=>{

    const check = checkStorage()
    if(!check){
        localStorage.setItem('OrderList', JSON.stringify([{ 'name': product.name, 'price': product.price, 'qty': 1, 'image': product.image }]))
        return setUpdateCart(!updateCart)
    }

    for(let items of check){
        if(items.name === product.name && items.price === product.price && items.image === product.image ){
            items.qty = items.qty + 1
            localStorage.setItem("OrderList", JSON.stringify(check))
            return setUpdateCart(!updateCart)
        }
    }
    check.push ({'name': product.name, 'price': product.price, 'qty': 1, 'image': product.image })
    localStorage.setItem('OrderList', JSON.stringify(check))
    return setUpdateCart(!updateCart)
}

export const removeFromCart = (product, updateCart, setUpdateCart)=>{

    const check = checkStorage()
    if(!check) return

    for(let items of check){
        if(items.name === product.name && items.price === product.price && items.image === product.image ){
            items.qty = items.qty - 1
            if(items.qty === 0){
                const updatedCheck = check.filter((item)=>item.name !== items.name)
                localStorage.setItem("OrderList", JSON.stringify(updatedCheck))
                return setUpdateCart(!updateCart)
            }
            localStorage.setItem("OrderList", JSON.stringify(check))
            return setUpdateCart(!updateCart)
        }
    }
    return 
}