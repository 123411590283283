import React, { useState, useContext } from 'react'
import hamburgerIcon from '../img/hamburger.png'
import search from '../img/search.png'
import cart from '../img/cart.png'
import { useNavigate } from 'react-router-dom'

import MobileDrawerMenu from './MobileDrawerMenu'
import { DrawerMenuContext } from '../Components/AllContexts'

function NavBarMenu() {

    const { displayMobileMenu, setDisplayMobileMenu  } = useContext(DrawerMenuContext)
    const [ inputBox, setInputBox ] = useState('')
    const navigate = useNavigate()


    const handleDisplayMobileMenu = () =>{
        setDisplayMobileMenu(true)
    }

    const handleChange = (e)=>{
        setInputBox(e.target.value)
    }

    const handleSubmit = (e)=>{
        const saveInput = inputBox
        setInputBox('')
        return navigate(`/products/${saveInput}`)
        
    }

    
  return (
    <>
    <MobileDrawerMenu />
    <div className='NavBarMenu-container' style={{ pointerEvents: displayMobileMenu ? 'none': 'auto', opacity: displayMobileMenu ? 0.5: 1 }}>
        <div className="mobile-navbar-container">
            <div className="navbar-hamburgericon-card">
                <img src={hamburgerIcon} alt="Menu Button" onClick={handleDisplayMobileMenu} className='hamburgerIcon' />
                <form className="search_input_container" onSubmit={handleSubmit}  >
                    <input type="text" placeholder='Search for products' className='searchInput' onChange={handleChange} />
                    <button><img src={search} alt="search" /></button>
                </form>
                {/* <img src={cart} alt="search" onClick={()=>setDisplayInputBox(!displayInputBox)} /> */}
            </div>
        </div>
    </div>
    </>
  )
}

export default NavBarMenu