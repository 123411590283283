import React from 'react'
import DashBoardVNav from '../SubComponents/DashBoardVNav'
import PortalHome from '../SubComponents/PortalHome'
import PortalCatalogue from '../SubComponents/PortalCatalogue'
import PortalManage from '../SubComponents/PortalManage'
import PortalShipping from '../SubComponents/PortalShipping'


function Portal({toShow}) {
  return (
    <div className="mainpage_container">
        <DashBoardVNav toSelect={toShow} />
        {toShow === 'home' && <PortalHome />}
        { toShow === 'campaign' && <PortalCatalogue /> }
        { toShow === 'manage' && <PortalManage /> }
        { toShow === 'shipping' && <PortalShipping /> }
    </div>
  )
}

export default Portal