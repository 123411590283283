import React, { useState, useContext, useEffect } from 'react'
import NavBar from '../Components/NavBar';
import { WhatsAppChatContext, ProductListContext, ShoppingCartContext } from '../Components/AllContexts';
import WhatsappChatInterFace from './WhatsappChatInterFace';
import RecommendedProducts from './RecommendedProducts';
import OceanDesign from './OceanDesign';
import { useParams } from 'react-router-dom';
import { addToCart, removeFromCart } from './functions';

function SingleProductPage() {

    const [count, setCount] = useState(0);
    const { displayChatInterface, setDisplayChatInterface } = useContext(WhatsAppChatContext)
    const { allProductList } = useContext(ProductListContext)
    const { id } = useParams()
    const [ filteredProduct, setFilteredProduct] = useState()
    const { updateCart, setUpdateCart, productsOnCart, setProductsOnCart  } = useContext(ShoppingCartContext)


  useEffect(() => {
    // Scroll to the top when the component is mounted
    window.scrollTo(0, 0);
  }, []);

  useEffect(()=>{
    const filteredArray = allProductList.filter(item => item.name === id);
    setFilteredProduct(filteredArray)
    
  },[id])

  useEffect(()=>{
    if(filteredProduct && productsOnCart){
        const filter = productsOnCart.filter(product => product.name === filteredProduct[0].name)
        console.log(filter[0]?.qty)
        if(!filter[0]?.qty){
            console.log(filter[0]?.qty)
            return setCount(0)
        }
        setCount(filter[0]?.qty)
    }

  },[filteredProduct, productsOnCart])

  return (
   <>
    {!displayChatInterface && <NavBar />}
    {displayChatInterface && <WhatsappChatInterFace />}
    {!displayChatInterface && filteredProduct && <div className='singleproductpage-container'>
        <div className="product-card">
            <img src={filteredProduct[0].image} alt="product" />
            <div className="product-details">
                <h3>{filteredProduct[0].name}</h3>
                <p>¥ {filteredProduct[0].price}</p>
            </div>
        </div>
        <div className="cart-buttons">
            <div className="counter">
                <button onClick={()=>removeFromCart(filteredProduct[0], updateCart, setUpdateCart)}>-</button>
                <span>{count}</span>
                <button onClick={()=>addToCart(filteredProduct[0], updateCart, setUpdateCart)}>+</button>
            </div>
            <button onClick={()=>addToCart(filteredProduct[0], updateCart, setUpdateCart)} className='more-btn'>Add to Cart</button>
        </div>
        <div className="productdescription-container">
            <small>Product Description</small>
            <p>{filteredProduct[0].description}</p>
        </div>
    </div>}
    {!displayChatInterface && filteredProduct && <RecommendedProducts title='Related Products' inCategory={`${filteredProduct[0].category}`} displayedProduct ={filteredProduct[0].name}  />}
    {!displayChatInterface && <OceanDesign />}
   </>
  )
}

export default SingleProductPage