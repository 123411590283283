import React, { useContext, useState, useEffect } from 'react'
import baso from '../img/baso1.jpg'
import { DrawerMenuContext, ProductListContext } from '../Components/AllContexts'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'


function CategoriesMenu() {

    const { displayMobileMenu, setDisplayMobileMenu  } = useContext(DrawerMenuContext)
    const [ searchProductName, setSearchProductName ] = useState('')
    let navigate = useNavigate('')
    const {allProductList, setAllProductList} = useContext(ProductListContext)
    const [ allCategories, setAllCategories ] = useState()


    useEffect(() => {
        if(allProductList){
            // const uniqueCategories = [...new Set(allProductList.map(product => product.category))];
            const uniqueCategories = [...new Set(allProductList
                .filter(product => product.category !== null && product.category !== undefined && product.category !== "")
                .map(product => product.category)
              )];
            setAllCategories(uniqueCategories);
        }
      }, [allProductList]);

    const handleCategoryNavigation = (category) =>{
        setDisplayMobileMenu(false)
        return navigate(`/products/${category}`)
    }

    const handleWebsiteNavigation = (toGo) =>{
        setDisplayMobileMenu(false)
        return navigate(`${toGo}`)
    }
  return (
    <>
    <div className='categoriesMenu_container'>
        <div className='categoriesMenu_card'>
            <div className="drawer_heading">
                <p>Product Category</p>
                
            </div>
            <ul className='categories'>
            {/* <small>Scroll to view all categories</small> */}
                {allCategories && allCategories.map((product)=> <li key={product} onClick={()=>handleCategoryNavigation(product)}>{product} <span>&gt;</span></li>)}
            </ul>
            <p>Our shops</p>
            <ul>
                <li onClick={()=>handleWebsiteNavigation('/')}>Halal Foods <span>&gt;</span></li>
                <li onClick={()=>handleWebsiteNavigation('/SkinCare')}>Skin Care <span>&gt;</span></li>
            </ul>
            <p>About Shipping</p>
            <ul>
                <li onClick={()=>handleWebsiteNavigation('/shipping/Yamato')}>Yamato <span>&gt;</span></li>
                <li onClick={()=>handleWebsiteNavigation('/shipping/Sagawa')}>Sagawa <span>&gt;</span></li>
            </ul>
        </div>
    </div>
    </>
  )
}

export default CategoriesMenu