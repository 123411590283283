import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

function Error() {

    const navigate = useNavigate()

    useEffect(()=>{
        return navigate('/')
    },[])
  return (
    <div>
        <h2>Nothing is found in this link</h2>
        <p>Redirecting you to NYI INTAN Home page</p>
    </div>
  )
}

export default Error