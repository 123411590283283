import React, { useState, useEffect, useContext } from "react";
import baso1 from '../img/baso1.jpg'
import baso2 from '../img/baso2.jpg'
import axios from "axios";
import catalogue from '../img/catalogue.jpeg'
import { DrawerMenuContext, SalesLink, ShoppingCartContext } from "../Components/AllContexts";
import { addToCart } from "./functions";

function ProductOnSale() {

    const [ productDetail, setProductDetail ] =useState({
        name: '',
        price: '',
        discountedprice: '',
        percent: '',
        description: '',
        url1: '',
        url2: ''
    })

    const { allCatalogues } = useContext(DrawerMenuContext)
    const { updateCart, setUpdateCart  } = useContext(ShoppingCartContext)

    const fetchLink = async()=>{
        const url = SalesLink
        try {
            const response = await axios.get(url)
            setProductDetail(response.data.data[0])            
        } catch (error) {
            console.log(error)
        }
    }

    const handleNavigation = ()=>{
        if(!allCatalogues) return
        return window.open(allCatalogues.halal, '_blank');
    }  
    
    const handleCart = (productItem)=>{
        const product = { name: productItem.name, price: productItem.discountedprice, qty: 1, image: productItem.url1 }
        return addToCart(product, updateCart, setUpdateCart)
    }

    useEffect(()=>{
        fetchLink()
    },[])


  return (
    <div className="productOnSale_displayboard">
        {productDetail.url1 ? <div className={productDetail.url1 ? 'displayboard-container': 'no_displayboard_container'}>
            <div className="displayboard">
                <h1>{productDetail.name}</h1>
                <p>{productDetail.description}</p>
                
               <div className="details">
                <div className="price">
                    <h4>¥ {productDetail.price}</h4>
                    <h3>¥ {productDetail.discountedprice}</h3>
                </div>
                <small>{productDetail.percent}% off</small>
                <button onClick={()=>handleCart(productDetail)}>Add to Cart</button>
               </div>
            </div>
            <div className="displayboardImage">
                <img src={productDetail.url1} alt={productDetail.name} />
                <h3>+</h3>
                <img src={productDetail.url2} alt={productDetail.name} />
            </div>
        </div>: <div className="alterdisplayboard-container">
            <img src={baso1} alt="" />
            <img src={baso2} alt="" />
        </div>}

        <div className="catalogue_container">
            <img src={catalogue} alt="merchandise" />
            <p onClick={handleNavigation}>View Our Catalogue</p>
        </div>
    </div>
  )
}

export default ProductOnSale