import React, { useState, useEffect} from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { ShippingLink } from '../Components/AllContexts'

function ShippingDetails() {

    const { id } = useParams()
    const [ displayImage, setDisplayImage] = useState()

    const fetchImage=async()=>{
        const url = ShippingLink
        try {
            const response = await axios.get(url)
            setDisplayImage(response.data.data[0])
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(()=>{
        fetchImage()
    }, [id])

  return (
    <div className='shippingDetails_container'>
        <h1>{id.toUpperCase()} Shipping Information Page</h1>
        {displayImage && id.toLowerCase() === 'yamato' && <div className="image_card">
            <img src={displayImage.yamato} alt="" />
        </div>}
        {displayImage && id.toLowerCase() === 'sagawa' && <div className="image_card">
            <img src={displayImage.yamato} alt="" />
        </div>}
        {!displayImage && <p>Loading.... Loading...</p>}
    </div>
  )
}

export default ShippingDetails