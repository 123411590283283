import React, { useContext, useRef } from 'react'
import logo from '../img/logo.jpg'
import hamburger from '../img/hamburger.png'
import { DrawerMenuContext, ShoppingCartContext } from '../Components/AllContexts'
import skincarecart from '../img/skincarecart.png'

function SkinCareComponentNavBar({ scrollToProductListing }) {

    const {  openDrawerMenu, setOpenDrawerMenu } = useContext(DrawerMenuContext)
    const { showShoppingCarts, setShowShoppingCarts, productsOnCart, setProductsOnCart } = useContext(ShoppingCartContext)


    const handleOpenDrawerMenu = ()=>{
        setOpenDrawerMenu(!openDrawerMenu)
    }

    const displayCart = ()=>{
        setShowShoppingCarts(!showShoppingCarts)
      }


  return (
    <>
    <div className='skinCareComponentNavBar_container'>
        <img src={logo} alt="logo" />
        <div className="horizontalMenu_card">
            <ul>
                <li>Home</li>
                <li onClick={()=>scrollToProductListing()}>Our Products</li>
                <li> <div>
                <img src={skincarecart} onClick={displayCart} alt="cart" />{productsOnCart && <h3>{productsOnCart.length}</h3> }  
                </div></li>
            </ul>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }} className='skincare_extra'>
            <div>
            {productsOnCart && <h3>{productsOnCart.length}</h3>}
            <img src={skincarecart} alt="cart" onClick={displayCart} className='hamburgerIcon' style={{ border: '1px solid black', padding: '5px' }} />
            </div>
            <img src={hamburger} alt="hamburger" className='hamburgerIcon' onClick={handleOpenDrawerMenu} />
        </div>
        <div className="waves">
            <div className="wave_card">
            </div>
            <div className="wave2_card">    
            </div>
        </div>
    </div>
    
    </>
  )
}

export default SkinCareComponentNavBar