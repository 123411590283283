import React, { useContext, useState, useEffect } from 'react'
import { WhatsAppChatContext } from '../Components/AllContexts'
import whatsapp from '../img/whatsapp.png'
import { useNavigate } from 'react-router-dom'

function WhatsappChatInterFace() {

  const { displayChatInterface, setDisplayChatInterface } = useContext(WhatsAppChatContext)
  const [ welcomeMessage, setWelcomeMessage ] = useState(['Welcome to NYI Intan. Thanks for visiting our website.'])
  const [ inputtedMsg, setInputtedMsg ] = useState('')
  const navigate = useNavigate()

  const handleCloseChat = ()=>{
    setWelcomeMessage(['Welcome to NYI Intan. Thanks for visiting our website.'])
    setDisplayChatInterface(!displayChatInterface)
  }


  useEffect(()=>{
    setTimeout(() => {
      setWelcomeMessage((prevMessages) => [
        ...prevMessages,
        "How may I help you today ?",
      ]);
    }, 3000);
  },[displayChatInterface])

  const handleSendMessage = ()=>{
    if(inputtedMsg.length <=0) return 
    window.location.href=`https://api.whatsapp.com/send?phone=+818014108889&text=${inputtedMsg}`
    return
  }

  const handleChange = (e) =>{
    setInputtedMsg(e.target.value)
  }


  return (
    <div className={`whatsappChatInterFace-container ${displayChatInterface ? 'open' : ''}`}>
        
        <div className="chatNavBar">
          <img src={whatsapp} alt="" />
          <div className="onlineStatus">
            <p>NYI INTAN</p>
            <small>Online</small>
          </div>
          <button className='end' onClick={handleCloseChat}>End Chat</button>
        </div>
        {/* <div className="chatHeadingMessage">
          <p>Regarding shipping, we can ship both through Yamato & Sagawa.</p>
        </div> */}
        <div className="messages-container">
          <p>Your message will be sent to +818014108889, NYI Intan official Number. <br /> After clicking send, you will be directed to your Whatsapp.</p>
            <div className="message_examples">
              { welcomeMessage.length > 0 && welcomeMessage.map((msg, index)=>(
                <div key={index} className="welcome_message">
                <img src={whatsapp} alt="whatsapp" />
                <p>{msg}</p>
              </div>
              )) }
    
            </div>
        </div>
        <div className="messageInput_container">
          <input type="text" name="message" id="message" onChange={handleChange} />
          <button onClick={handleSendMessage}>Send</button>
        </div>
    </div>
  )
}

export default WhatsappChatInterFace