import axios from 'axios'
import React, { useState } from 'react'
import DropboxChooser from 'react-dropbox-chooser'
import { ShippingLink } from '../Components/AllContexts'

function PortalShipping() {
    const API_Key = process.env.REACT_APP_API_Key
    const [ link, setLink ] = useState({
        yamato: '',
        sagawa: ''
    })

    const [ successMsg, setSuccessMsg ] = useState('')

    const saveImage = async (files) => {
        const link = files[0].link
        const modifiedUrl = link.replace("dropbox.com", "dl.dropboxusercontent.com");
        setLink((prevState)=>({
            ...prevState,
            ['yamato']: modifiedUrl
        }))
      }

      const saveSecondImage = async (files) => {
        const link = files[0].link
        const modifiedUrl = link.replace("dropbox.com", "dl.dropboxusercontent.com");
        setLink((prevState)=>({
            ...prevState,
            ['sagawa']: modifiedUrl
        }))
      }

      const sendData = async()=>{
        if(link.yamato !== '' && link.sagawa !== ''){
            const url = ShippingLink
            try {
                const response = await axios.post(url, JSON.stringify(link), {
                    headers: {
                      'Content-Type': 'application/x-www-form-urlencoded'
                    }
                  });
                setSuccessMsg('Success')
            } catch (error) {
              alert('Something went wrong. Please try again..')
                // console.log(error)
            }
        }
      }
  return (
    <div className='PortalCatalogue_container Shipping'>
      {successMsg && <h5>Successfully uploaded...</h5>}
        <DropboxChooser 
                appKey={API_Key}
                success={saveImage}
                cancel={() => console.log('closed')}
                multiselect={true}
                extensions={['.jpg','.jpeg','.png', '.webp']} >
                <div className={`${link.yamato ? 'dropboxWithFile' : 'dropbox-button '}`}>Upload Yamato</div>          
        </DropboxChooser>
        <DropboxChooser 
                appKey={API_Key}
                success={saveSecondImage}
                cancel={() => console.log('closed')}
                multiselect={true}
                extensions={['.jpg','.jpeg','.png', '.webp']} >
                <div style={{ marginTop: '10px' }} className={`${link.sagawa ? 'dropboxWithFile' : 'dropbox-button '}`}>Upload Sagawa</div>          
        </DropboxChooser>
        <button onClick={sendData}>Submit</button>
    </div>
  )
}

export default PortalShipping